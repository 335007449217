<template>
    <div style="overflow-y: hidden;">
        <div class="page_title">
            {{$i18n.t('dashboard.text1')}}
        </div>

        <!-- 头部产品详情 start -->
        <div class="product_msg" v-if="productList.length > 0">
            <el-image :src="productData.productImageUrl" class="product_img">
                <div slot="error">
                    <div class="image_slot">
                        {{$i18n.t('dashboard.text2')}}
                    </div>
                </div>
            </el-image>
            <div class="product_msg_list">
                <div class="product_msg_top">
                    <div class="product_name">{{ productData.productName }}</div>

                    <el-dropdown size="small" @command="handleCommand" trigger="click">
                        <el-button class="product_edit" size="mini" plain type="primary">
                            {{$i18n.t('dashboard.text3')}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu :append-to-body="false" slot="dropdown">
                            <el-dropdown-item
                                v-for="(item, index) in productList"
                                :command="item"
                                :class="currentProduct.code === item.code ? 'company_list_item_activate':''"
                                :disabled="currentProduct.code === item.code"
                                :key="index">
                                {{ item.name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>

                </div>
                <div class="product_msg_bottom">
                    <div class="product_msg_item">PID：{{ productData.pid }}</div>
                    <div class="product_msg_item">{{$i18n.t('dashboard.text4')}} {{ productData.categoryName }}</div>
                </div>
            </div>
        </div>
        <!-- 头部产品详情 end -->

        <div class="table_card">
            <div class="header">
                <span class="title">{{$i18n.t('dashboard.text1')}}<span class="subtitle">{{$i18n.t('dashboard.text5')}}</span></span>

                <el-input :placeholder="selectType === 1 ? $i18n.t('dashboard.text6') : $i18n.t('dashboard.text41')" style="width: 500px;" v-model="keywords" size="small" class="input-with-select">
                    <el-select v-model="selectType" slot="prepend" @change="keywords = ''" style="width: 180px;" :placeholder="$i18n.t('dashboard.text7')">
                        <el-option :label="$i18n.t('dashboard.text8')" :value="1"></el-option>
                        <el-option :label="$i18n.t('dashboard.text9')" :value="2"></el-option>
                    </el-select>
                    <el-button slot="append" type="text" icon="el-icon-search" @click="getStatisticsByOrderNo(1)"></el-button>
                </el-input>
            </div>

            <el-table
                ref="factoryTestTable"
                class="factoryTestTable"
                :data="factoryTest.list"
                :height="tableHeight"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                v-loading="loading"
                @sort-change="sortChange"
                style="width: 100%;">
                <el-table-column
                    type="index"
                    width="100"
                    :label="$i18n.t('dashboard.text10')">
                </el-table-column>
                <el-table-column
                    width="300"
                    :label="$i18n.t('dashboard.text8')">
                    <template slot-scope="scope">
                    {{ scope.row.orderNo ? scope.row.orderNo : "--" }}
                    </template>
                </el-table-column>
                <el-table-column
                    width="300"
                    :label="$i18n.t('dashboard.text9')">
                    <template slot-scope="scope">
                        {{scope.row.address ? scope.row.address : scope.row.ip}}
                        <el-button type="text" icon="el-icon-edit" @click="updateAdd(scope.row)" style="margin-left: 10px;" v-if="scope.row.ip"></el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    sortable="custom"
                    prop="total_amount"
                    width="180"
                    :label="$i18n.t('dashboard.text11')">
                    <template slot-scope="scope">
                        {{scope.row.successAmount + scope.row.failureAmount}}
                    </template>
                </el-table-column>
                <el-table-column
                    sortable="custom"
                    prop="success_amount"
                    width="180"
                    :label="$i18n.t('dashboard.text12')">
                    <template slot-scope="scope">
                        {{scope.row.successAmount}}
                    </template>
                </el-table-column>
                <el-table-column
                    sortable="custom"
                    prop="failure_amount"
                    width="180"
                    :label="$i18n.t('dashboard.text13')">
                    <template slot-scope="scope">
                        {{scope.row.failureAmount}}
                    </template>
                </el-table-column>
                <el-table-column
                    sortable="custom"
                    prop="failure_rate"
                    width="200"
                    :label="$i18n.t('dashboard.text14')">
                    <template slot-scope="scope">
                        <span :style="{color: scope.row.failureAmount > 0 ? 'red' : ''}">
                            {{(scope.row.failureAmount/(scope.row.successAmount + scope.row.failureAmount)*100).toFixed(3) + '%'}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    width="240"
                    :label="$i18n.t('dashboard.text15')">
                    <template slot-scope="scope">
                        {{scope.row.startTime}} <br/> {{scope.row.endTime}}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    :label="$i18n.t('dashboard.text16')"
                    width="120">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="showMsgDrawer(scope.row)"
                            size="small">
                            {{$i18n.t('dashboard.text17')}}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="10"
                layout=" prev, pager, next"
                style="margin-top: 10px;text-align: right;"
                :total="parseInt(factoryTest.totalRecords)">
            </el-pagination>
        </div>

        <factory-test-msg-drawer/>
        <el-dialog :title="$i18n.t('dashboard.text18')" width="400px" @close="handleClose" :visible.sync="updateAddressDialogVisible">
            <el-form :model="addressForm" size="small" :rules="rules" ref="ruleForm" style="margin-top: 40px;">
                <el-form-item prop="phone">
                    <el-input v-model.trim="addressForm.address" :placeholder="$i18n.t('dashboard.text19')"></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
            <el-button @click="updateAddressDialogVisible = false">{{$i18n.t('dashboard.text20')}}</el-button>
            <el-button type="primary" @click="updateAddress()" :loading="updateAddressLoading">{{ $i18n.t('dashboard.text21') }}</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import FactoryTestMsgDrawer from "@/views/dashboard/components/factoryTestMsgDrawer";

export default {
    name: "dashboard",
    components: {FactoryTestMsgDrawer},
    computed: {
        ...mapState('product', ['productMsg']),
        ...mapState('factoryTest', ['factoryTestMsgDrawerVisible'])
    },

    watch: {
        //产品详情
        productMsg: {
            handler(newVal) {
                this.productData = {
                    productName: newVal.name,
                    pid: newVal.pid,
                    productImageUrl: newVal.icon,
                    categoryName: newVal.categoryName ? (this.language === 'zh' ? JSON.parse(newVal.categoryName).zh : JSON.parse(newVal.categoryName).en) : ""
                }
            },
            deep: true
        }
    },

    data() {
        return {

            //产品详情
            productData: {
                //产品名称
                productName: "",
                pid: "",
                //产品拟物图
                productImageUrl: "",
                //品类名称
                categoryName: ""
            },

            updateAddressDialogVisible: false,

            addressForm: {
                address: "",
                ip: ""
            },

            rules: {
                address: [
                    {required: true, message: this.$i18n.t('dashboard.text19'), trigger: "blur"},
                ]
            },

            productList: [],

            currentProduct: {},

            tableHeight: 50,

            keywords: "",

            selectType: 1,

            page: 1,

            factoryTest: {
                list: [],
                totalRecords: 10
            },

            loading: false,

            updateAddressLoading: false,
            language: localStorage.getItem('language')
        }
    },

    mounted() {
        setTimeout(() => {
            this.tableHeight = (window.innerHeight - 380);
        }, 100);
        window.addEventListener('resize', this.setHeight);
        this.getProductMsg();
    },
    methods: {

        ...mapActions('product', ['queryProduct', 'getProductDetail', 'queryDevice', 'delVirtualDevice', 'unBindPlatformDevice']),
        ...mapActions('factoryTest', ['queryDeviceStatisticsByOrderNo', 'modifyIpAddress']),

        /**
         * 查询设备厂测结果统计
         */
        getStatisticsByOrderNo(page, orderBy, isAsc) {
            this.loading = true;
            if (page) this.page = page;
            let data = {
                currPage: this.page,
                pageSize: 10,
                pid: this.productData.pid
            }
            if (orderBy && isAsc) data.orderBy = orderBy;
            if (isAsc) data.isAsc = isAsc === "ascending";
            if (this.selectType === 1) {
                (this.keywords !== "") && (data.orderNo = this.keywords);
            } else {
                (this.keywords !== "") && (data.address = this.keywords);
            }
            this.queryDeviceStatisticsByOrderNo(data).then(res => {
                this.factoryTest = res.result;
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => this.loading = false)
        },

        /**
         * 获取产品列表
         */
        getProductMsg() {
            this.queryProduct({
                currPage: 1,
                pageSize: 10000,
            }).then(async res => {
                this.productList = res.result.list ? res.result.list : [];

                if (!res.result.list && res.result.list.length <= 0) return;
                if (this.$route.query.id) {
                    res.result.list.forEach(item => {
                        if (this.$route.query.id === item.code) {
                            this.currentProduct = item;
                        }
                    })
                } else {
                    this.currentProduct = res.result.list[0];
                    this.$config.changeURLArg('id', res.result.list[0].code);
                }
                await this.getProductDetail({
                    productCode: this.currentProduct.code,
                    isRefresh: true
                });
                this.getStatisticsByOrderNo();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 切换产品回调
         * @param command
         */
        async handleCommand(command) {
            this.$config.changeURLArg('id', command.code);
            this.currentProduct = command;
            await this.getProductDetail({
                productCode: command.code,
                isRefresh: true
            });
            this.getStatisticsByOrderNo();
        },

        /**
         * 设备表格高度
         */
        setHeight() {
            this.tableHeight = (window.innerHeight - 380);
        },

        handleCurrentChange(page) {
            this.getStatisticsByOrderNo(page);
        },

        showMsgDrawer(row) {
            console.log('showMsgDrawer');
            this.$store.commit("factoryTest/SET_SELECT_ORDER", row);
            this.$store.commit("factoryTest/SET_FACTORY_TEST_MSG_DRAWER_VISIBLE", true);
        },

        sortChange({ prop, order }) {
            console.log(prop, order);
            this.getStatisticsByOrderNo(1, prop, order);

        },

        updateAdd(row) {
            this.addressForm = {
                address: row.address,
                ip: row.ip
            };
            this.updateAddressDialogVisible = true;
        },

        updateAddress() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.updateAddressLoading = true;
                    this.modifyIpAddress(this.addressForm).then(res => {
                        this.$dialog.showMessage(this.$i18n.t('dashboard.text22'), this.$config.TOAST_SUCCESS);
                        this.getStatisticsByOrderNo();
                        this.updateAddressDialogVisible = false;
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    }).finally(() => {
                        this.updateAddressLoading = false;
                    })
                }
            })
        },

        handleClose() {
            this.updateAddressDialogVisible = false;
            this.updateAddressLoading = false;
            this.addressForm = {
                address: "",
                ip: ""
            }
        }

    }
}
</script>

<style scoped>

.el-breadcrumb {
    line-height: normal;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
    font-size: 16px !important;
    color: black !important;
}

/deep/ .el-dropdown-menu {
    position: relative!important;
    left: 0!important;
    top: 22px!important;
    z-index: 6!important;
}

/deep/ .el-dropdown-menu__item {
    white-space: nowrap;
}

/deep/ .el-dropdown-menu__item:first-child {
    margin-top: 4px!important;
}

.product_msg .el-empty {
    padding: 0px!important;
}

.product_msg /deep/ .el-empty__description {
    margin-top: 10px!important;
}

.product_msg /deep/ .el-empty__description p {
    font-size: 12px!important;
}

.product_edit {
    float: left;
    margin-left: 21px;
    height: 22px;
    padding: 3px;
}

.image_slot {
    background-color: #F7F7F7;
    width: 50px;
    height: 50px;
    font-size: 12px;
    text-align: center;
    padding: 13px 5px 0 5px;
    color: #c0c4cc;
}

.product_msg {
    height: 80px;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 64px 24px 24px 24px;
    box-shadow: 0 0 4px 0 #BCBCBC;
}

.product_img {
    width: 60px;
    height: 60px;
    float: left;
    background-color: #f7f7f7;
    border-radius: 8px;
    margin: 10px 20px;
}

.product_msg_list {
    height: 60px;
    margin: 10px 0;
    float: left;
}

.product_msg_top {
    height: 22px;
    padding: 7px 0;
    text-align: left;
}

.product_name {
    font-weight: bold;
    font-size: 16px;
    color: black;
    float: left;
    line-height: 22px;
}

.company_list_item_activate {
    background-color: #e8e9ed;
    color: #494d72;
}

.product_msg_bottom {
    height: 22px;
}

.product_msg_item {
     float: left;
     font-size: 10px;
     color: #595959;
     font-weight: 400;
     margin-left: 40px;
 }

.product_msg_item:first-child {
    margin-left: 0;
}

.table_card {
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 24px 24px 20px 24px;
    box-shadow: 0 0 4px 0 #BCBCBC;
    padding: 10px 24px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0 20px 0;
}

.title {
    font-weight: bold;
    font-size: 16px;
}

.subtitle {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    margin-left: 4px;
}

.input-with-select {
    width: 360px;
}

/deep/ .el-table__body .el-table__row.hover-bg td{
    background-color: #DEE0E5 !important;
}

</style>
